<template>
  <div class="works-detail">
    <div class="wd-inner">
      <div @click="handleClose" class="close">X</div>
      <div class="scroll-box">
        <div class="top">
          <div class="wdt-left">
            <div class="swiper-box" v-if="info && info.digitalCollectionFileType">
              <!-- 图片 -->
              <el-carousel
                ref="carousel"
                v-if="info.digitalCollectionFileType === 2"
                @change="handleCarouselChange"
                width="343px"
                height="363px"
                :autoplay="false"
              >
                <el-carousel-item v-for="item in getImgList" :key="item">
                  <el-image style="width: 100%; height: 100%" :src="item" fit="contain"  oncontextmenu="return false"></el-image>
                  <div class="watermark-box"></div>
                </el-carousel-item>
              </el-carousel>
              <div class="divModel">
                <el-image
                  v-if="info.digitalCollectionFileType === 4"
                  style="width: 100%; height: 100%"
                  :src="getImgList"
                  fit="contain"  oncontextmenu="return false"
                ></el-image>
                <div class="watermark-box"></div>
              </div>

              <!-- 视频 -->
              <!-- <video v-if="info.digitalCollectionFileType === 3" style="width: 343px; height: 363px;" :src="info.digitalCollectionFileUrl" controls="controls" autoplay loop>1</video> -->
              <!-- 模型 -->
              <!-- <div v-if="info.digitalCollectionFileType === 1">
                <img style="width: 100%; height: 100%" :src="info.coverFileUrl" alt="">
              </div>-->
              <div
                style="width: 343px; height: 363px;"
                class="divModel"
                v-if="info.digitalCollectionFileType==1"
              >
                <iframe
                  :src="make3durl(info.digitalCollectionFileUrl)"
                  style="width: 343px; height: 363px;border:none;"
                ></iframe>
                <div class="watermark-box"></div>
              </div>
            </div>
            <div class="img-list" v-if="getImgList.lnegth >= 1">
              <div
                @click="handleImgChoose(index)"
                v-for="(item, index) of getImgList "
                :key="index"
                class="img-item"
                :class="{'img-item-active': activeIndex === index}"
              >
                <img style="width: 100%; height: 100%" :src="item" alt />
              </div>
            </div>
          </div>
          <!-- <div class="center-line"></div> -->
          <div class="wdt-right" v-if="info">
            <div class="title">{{info.collectionName}}</div>
            <div class="desc" v-html="info.collectionIntroduce">
              <!-- {{info.collectionIntroduce}} -->
            </div>
            <div class="des-list">
              <!-- 设计师 designerInfo -->
              <div
                class="des-item"
                v-for="(item, index) of info.designerInfo"
                :key="'designerInfo' + index"
              >
                <div class="di-avatar">
                  <img :src="item.logoUrl" alt />
                </div>
                <div class="di-name-part">
                  <p class="di-tag">设计师</p>
                  <p class="di-name">{{item.name}}</p>
                </div>
              </div>
              <!-- 版权方 copyrightInfo -->
              <div
                class="des-item"
                v-for="(item, index) of info.copyrightInfo"
                :key="'copyrightInfo' + index"
              >
                <div class="di-avatar">
                  <img :src="item.logoUrl" alt />
                </div>
                <div class="di-name-part">
                  <p class="di-tag">版权方</p>
                  <p class="di-name">{{item.name}}</p>
                </div>
              </div>
              <!-- 生产方 producerInfo -->
              <div
                class="des-item"
                v-for="(item, index) of info.producerInfo"
                :key="'producerInfo' + index"
              >
                <div class="di-avatar">
                  <img :src="item.logoUrl" alt />
                </div>
                <div class="di-name-part">
                  <p class="di-tag">生产方</p>
                  <p class="di-name">{{item.name}}</p>
                </div>
              </div>
              <!-- 发型方 issuerInfo -->
              <div
                class="des-item"
                v-for="(item, index) of info.issuerInfo"
                :key="'issuerInfo' + index"
              >
                <div class="di-avatar">
                  <img :src="item.logoUrl" alt />
                </div>
                <div class="di-name-part">
                  <p class="di-tag">发行方</p>
                  <p class="di-name">{{item.name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom" v-if="info" v-html="info.collectionStory"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { collectionInfo } from "@/api/index";
export default {
  name: "Detail",
  props: {
    collectionNumber: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      info: null,
      activeIndex: 0
    };
  },
  mounted() {
    this.collectionInfo();
  },
  methods: {
    handleClose() {
      this.$emit("onClose");
    },
    async collectionInfo() {
      let response = await collectionInfo(this.collectionNumber);
      if (response.code === 200) {
        this.info = response.data;
      } else {
        this.$message.error(response.msg);
      }
    },
    // 走马灯切换
    handleCarouselChange(val) {
      console.log(">", val);
      this.activeIndex = val;
    },
    handleTo3D() {
      // window.open(`${process.env.VUE_APP_THREED_URL}?url=${encodeURIComponent(this.info.digitalCollectionFileUrl)}&title=${this.info.collectionName}`);
      window.open(
        `${process.env.VUE_APP_THREED_URL}?url=${encodeURIComponent(
          this.info.digitalCollectionFileUrl.substr(8)
        )}&title=${this.info.collectionName}&noControl&noRotate`
      );
    },
    handleImgChoose(val) {
      this.$refs.carousel.setActiveItem(val);
    },
    make3durl(url) {
      var modelUrl = "";
      modelUrl = `${process.env.VUE_APP_THREED_URL}?url=${encodeURIComponent(
        url.substr(8)
      )}&title=${this.info.collectionName}&noControl&noRotate&type=2`;
      return modelUrl;
    }
  },
  computed: {
    getImgList() {
      if (this.info && this.info.digitalCollectionFileUrl) {
        return this.info.digitalCollectionFileUrl.split(",");
      } else {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.center-line {
  width: 1px;
  height: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  background: #ededed;
}
.works-detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  .wd-inner {
    width: 750px;
    background: #fff;
    position: relative;
    padding: 20px;
    .close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      background: #ff6c19;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    .top {
      display: flex;
      border-bottom: 0.5px solid #ededed;
      padding-bottom: 17px;
      .wdt-left {
        margin-right: 22px;
        .swiper-box {
          width: 343px;
          height: 363px;
          margin-bottom: 17px;
          position: relative;
        }
        .img-list {
          width: 343px;
          overflow: auto;
          display: flex;
          .img-item {
            width: 42px;
            height: 42px;
            margin-right: 7px;
            flex-grow: 0;
            flex-shrink: 0;
          }
        }
      }
      .wdt-right {
        padding: 0 23px;
        height: 100%;
        border-left: 1px solid #ededed;
        .title {
          font-size: 18px;
          height: 25px;
          line-height: 25px;
          color: #000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin-bottom: 17px;
          font-weight: 600;
        }
        .desc {
          font-size: 14px;
          line-height: 19px;
          color: #333;
          margin-bottom: 30px;
          font-weight: 400;
          white-space: pre-wrap;
        }
      }
    }
  }
  .des-list {
    .des-item {
      display: flex;
      margin-bottom: 19px;
      .di-avatar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 14px;
        background: #c4c4c4;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .di-name-part {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .di-tag {
          color: #666666;
          font-weight: 300;
        }
        .di-tag {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .di-name {
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
  }
}
.scroll-box {
  max-height: 90vh;
  overflow: auto;
}
.bottom {
  padding-top: 20px;
  /deep/img {
    width: 100% !important;
    vertical-align: middle;
  }
}
.threedicon {
  position: absolute;
  bottom: 27px;
  left: 141px;
  width: 66px;
  height: 20px;
  cursor: pointer;
}
::v-deep {
  .el-carousel__indicator {
    padding: 12px 2px;
    .el-carousel__button {
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }
}
.divModel {
  position: relative;
}
.watermark-box {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
  background-image: url("../assets/images/watermark.png");
  background-size: 60% auto;
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  opacity: 0.65;
}
</style>