<template>
  <div class="works-details">
    <div slot="head" class="user-part" v-if="info">
      <div class="user-avatar">
        <img :src="info.userPicture" alt="">
      </div>
      <div class="user-desc">
        <div class="name">{{info.userName}}</div>
        <div class="info">区块链地址名称：{{info.walletName}}</div>
        <div class="info">区块链地址：{{info.address}}</div>
      </div>
    </div>
    <div class="works-scroll">
      
      <Waterfall
        ref="waterfall"
        :imgWidth="220"
        :maxCols="5"
        :imgsArr="workList"
        srcKey="coverFileUrl"
        @preloaded="handlePreloaded"
        @scrollReachBottom="handleScrollReachBottom"
        @onClick="handleClick"
      >
      </Waterfall>
    </div>
    <Detail :collectionNumber="collectionNumber" @onClose="visible = false" v-if="visible" />
  </div>
</template>

<script>
import { geCollectionDetail, geCollectionDetailHead } from '@/api/index'
import Waterfall from './Waterfall'
import Detail from '@/components/Detail'

export default {
  name: "WorksDetail",
  data() {
    return {
      workList: [],
      pageSize: 30,
      pageNum: 1,
      collectionNumber: '',
      info: null,
      visible: false,
    }
  },
  components: {
    Waterfall,
    Detail,
  },
  mounted() {
    this.walletNumber = this.$route.query.walletNumber;
    this.geCollectionDetail();
    this.geCollectionDetailHead();
  },
  methods: {
    handlePreloaded() {
      console.log('handlePreloaded')
    },
    handleScrollReachBottom() {
      this.pageNum = this.pageNum + 1;
      this.geCollectionDetail();
    },
    handleClick(e) {
      
      this.collectionNumber = e.collectionNumber;
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    async geCollectionDetailHead() {
      let response = await geCollectionDetailHead(this.walletNumber);
      if(response.code === 200) {
        this.info = response.data;
      } else {
        this.$message.error(response.msg)
      }
    },
    async geCollectionDetail() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        walletNumber: this.walletNumber,
      }
      let response = await geCollectionDetail(params)
      if(+response.code === 200){
        if(response.data.list.length > 0) {
          this.workList.push(...response.data.list)
        } else {
          this.$refs.waterfall.waterfallOver()
        }
        
      } else {
        this.$message.error(response.msg);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.works-details{
  height: 100vh;
  overflow: hidden;
  .user-part{
    position: fixed;
    top: 39px;
    left: 50%;
    z-index: 2;
    margin-left: -600px;
    width: 1200px;
    // margin: 0 auto;
    margin-bottom: 30px;
    .user-avatar{
      width: 150px;
      height: 150px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      z-index: 2;
      background: #eee;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .user-desc{
      position: absolute;
      top: 9px;
      left: 0;
      height: 130px;
      background: #FF6C19;
      width: 100%;
      border-radius: 65px;
      color: #fff;
      padding: 20px 0 20px 180px;
      .name{
        font-size: 20px;
        margin-bottom: 5px;
      }
      .info{
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
}
.works-scroll{
  height: 100%;
}
</style>