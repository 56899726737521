<template>
  <div
    class="works"
    v-infinite-scroll="handleScrollReachBottom"
    :infinite-scroll-distance="50"
  >
    <!-- <Waterfall
      ref="waterfall"
      :imgWidth="220"
      :maxCols="5"
      :imgsArr="workList"
      srcKey="coverFilePath"
      @preloaded="handlePreloaded"
      @scrollReachBottom="handleScrollReachBottom"
      @onClick="handleClick"
    /> -->
    <!-- <div class="vue-waterfall-easy-container">
      <div class="img-box" v-for="(v,i) in imgsArr_c" :class="[cardAnimationClass, {__err__: v._error}]" :style="{padding: (isMobile ? mobileGap : gap)/2+'px', width: isMobile ? '' : colWidth+'px'}">
          <div class="img-inner-box" @click="handleClick(v)">
            <img class="img" :src="v[srcKey]" :style="{width:imgWidth_c + 'px',height:v._height ? v._height+'px':false}" alt="">
            <div class="title" :title="v.info">{{v.collectionName}}</div>
            <template v-for="(item, index) of v.issuerInfos">
              <div class="user-info" :key="index" >
                <img class="user-avatar" v-if="item.issuerAvatarPath" :src="item.issuerAvatarPath" :alt="item.issuerName">
                <div class="user-name">{{item.issuerName}}</div>
              </div>
            </template>
          </div>
        </div>
    </div> -->
    <div class="list">
      <div class="con" v-if="workList.length">
        <div
          class="img-box"
          v-for="(v, i) in workList"
          :key="v.collectionNumber"
        >
          <div class="img-inner-box" @click="handleClick(v)">
            <img class="img" :src="v.coverFilePath" alt="" />
            <div class="title" :title="v.info">{{ v.collectionName }}</div>
            <div class="user-info" :key="index" v-if="v.issuerInfos.length>0">
              <img
                class="user-avatar"
                v-if="v.issuerInfos[0].issuerAvatarPath"
                :src="v.issuerInfos[0].issuerAvatarPath"
                :alt="v.issuerInfos[0].issuerName"
              />
              <div class="user-name">{{ v.issuerInfos[0].issuerName }}</div>
            </div>
            <!-- <template v-for="(item, index) of v.issuerInfos">
            <div class="user-info" :key="index" >
              <img class="user-avatar" v-if="item.issuerAvatarPath" :src="item.issuerAvatarPath" :alt="item.issuerName">
              <div class="user-name">{{item.issuerName}}</div>
            </div>
          </template> -->
          </div>
        </div>
      </div>
      <div class="no_data" v-else>暂无数据</div>
      <Footer class="cus_footer" />
    </div>
    <Detail
      :collectionNumber="collectionNumber"
      @onClose="visible = false"
      v-if="visible"
    />
  </div>
</template>

<script>
import { getWorkList } from "@/api/index";
import Waterfall from "@/components/Waterfall";
import Detail from "@/components/Detail";
import Footer from "@/components/Footer";
export default {
  name: "works",
  components: {
    Waterfall,
    Detail,
    Footer,
  },
  data() {
    return {
      visible: false,
      pageSize: 25,
      pageNum: 1,
      workList: [],
      collectionNumber: "",
      hasMore: true,
      isLoading: false,
    };
  },
  mounted() {
    // this.getWorkList();
  },
  methods: {
    async getWorkList() {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      };
      if (this.hasMore === false) {
        return;
      }
      console.log(1111);
      if (this.isLoading === false) {
        this.isLoading = true;
        let response = await getWorkList(params);
        this.isLoading = false;
        if (+response.code === 200) {
          if (response.data.list.length > 0) {
            this.hasMore = true;
            this.workList.push(...response.data.list);
          } else {
            this.hasMore = false;
          }
          this.pageNum = this.pageNum + 1;
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    handlePreloaded() {
      console.log("handlePreloaded");
    },
    handleScrollReachBottom() {
      // if(this.hasMore === false) {
      //   return
      // }

      this.getWorkList();
    },
    handleClick(e) {
      this.collectionNumber = e.collectionNumber;
      this.$nextTick(() => {
        this.visible = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.works {
  // display: flex;
  // justify-content: center;
  // background: #fff;
  // min-height: calc(100vh - 221px);
  height: 100vh;
  overflow: auto;

  .cus_footer {
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .list {
    position: relative;
    width: 100%;
    min-height: 100%;
  }

  .no_data {
    position: relative;
    top: 200px;
    text-align: center;
    color: #999;
  }

  .con {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    // height: 100%;
    margin: 0 auto;
    padding: 130px 0 220px;
    // overflow: auto;

    .img-box {
      width: 240px;
      padding: 10px;

      .img-inner-box {
        cursor: pointer;
      }

      .img {
        width: 220px;
        height: 230px;
        margin-bottom: 11px;
      }

      .title {
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 11px;
        font-weight: 600;
      }

      .user-info {
        display: flex;
        height: 30px;
        align-items: center;
        margin-bottom: 10px;

        .user-avatar {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          background: #eee;
          margin-right: 11px;
        }

        .user-info {
          display: flex;
          height: 30px;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }

  }
}
</style>
